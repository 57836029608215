import React from "react"

import Layout from "components/layout"
import SEO from "components/seo"

export default () => (
  <Layout>
    <div className="content px2 py3 mx-auto">
      <SEO
        title="Contact"
        meta={[
          {
            name: "description",
            content: "Contact the Surrey Health and Beauty Spa",
          },
          { name: "keywords", content: "beauty, spa, surrey" },
        ]}
      />

      <h1 className="h4 caps bold mt0 mb3 center">Contact</h1>

      <div className="sm-flex">
        <div className="sm-col-6">
          <h2 className="h2 mt0 light">By phone</h2>

          <p>
            Carshalton: <a href="tel:0203 773 3452">0203 773 3452</a>
          </p>

          <h2 className="h2 mt0 light">By email</h2>

          <p>
            <a href="mailto:info@surreyhealthandbeautyspa.com">
              info@surreyhealthandbeautyspa.com
            </a>
          </p>
        </div>
        <div className="sm-col-6">
          <h2 className="h2 mt0 light">Clinic addresses</h2>

          <p>
            <span className="block">
              <b>Surrey Health and Beauty Spa</b>
            </span>
            <span className="block">Westcroft Leisure Centre</span>
            <span className="block">Westcroft Road</span>
            <span className="block">Carshalton</span>
            <span className="block">Surrey, SM5 2TG</span>
          </p>

          <table className="expanded-table col-12">
            <thead>
              <tr>
                <th className="left-align">Day</th>
                <th className="left-align">Opening Hours</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Monday – Friday</td>
                <td>
                  <b>0900 – 2000</b>
                </td>
              </tr>
              <tr>
                <td>Saturday</td>
                <td>
                  <b>0900 – 1400</b>
                </td>
              </tr>
            </tbody>
          </table>

          <p>
            <small>
              <b>Parking:</b> There is free parking available on site.
              <br />
              <b>How to find:</b> Our treatment room is located within the Day
              Spa at Westcroft. Please say you are seeing the osteopath or
              physiotherapist and go right past reception and follow signs to
              the Day Spa. There is a waiting room in the Spa area.
            </small>
          </p>

          <p>
            <a href="https://goo.gl/maps/auaa2" className="clinic__map">
              Google Map
            </a>
          </p>
        </div>
      </div>
    </div>
  </Layout>
)
